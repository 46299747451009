import React from 'react';
import copyIcon from 'images/copy-link-icon.svg';
import { EmbedLinkContainer } from '../styles';

const EmbedLink = ({
  show,
  openCopyEmbedLinkDialog,
  classNames,
  label = 'Add tool to your website',
}) => {
  const handleOnClick = () => {
    if (openCopyEmbedLinkDialog) {
      openCopyEmbedLinkDialog(true);
    }
    dataLayer.push({
      event: 'cta clicked',
      cta_text: label,
      category: 'link',
      page_title: document.title,
      url: window.location.href,
      label,
    });
  };

  return show ? (
    <EmbedLinkContainer className={classNames} onClick={handleOnClick}>
      <img src={copyIcon} alt="" />
      <p>{label}</p>
    </EmbedLinkContainer>
  ) : null;
};

export default EmbedLink;
